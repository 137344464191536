import { useState, Fragment, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Avatar from "@mui/material/Avatar";
import { logEvent } from "../Analytics/google-analytics";
import { useRouter } from "next/router";
import { companyContacts } from "../../lib/constants/CompanyContacts";
import { COUNTRY_LIST } from "../../constant";
import style from "./header.module.css";
import { trackClick } from "../../scripts/segment";
import { generatePageName, pageName } from "../../lib/utils/events_utils";
import Link from "next/link";
import { fireEvent } from "../../utils/ga";
import { EVENTS_CONSTANTS } from "../../constant/events";
import withLogin from "../Login/withLogin";
import { flowTypes } from "../Login/LoginConstants";
import Image from "next/image";
import dynamic from "next/dynamic";
import { imageBasePath } from "lib/utils/imageUtils";
import { localStorageUtils } from "lib/utils/storageUtils";
import useLogin from "lib/hooks/useLogin";
import { underlineLastNWords } from "lib/utils/StringUtils";
import { ctaText } from "lib/utils/constants";
const EllisBanner = dynamic(() => import("components/BankAccount/EllisBanner"));

const Header = (props) => {
  const {
    showBackground,
    middleElement,
    showNavLinks,
    forceMobileBackground,
    invertLogo,
    showAvatar,
    user,
    loading,
    initLogin,
    mainBannerText,
    showEllisBanner,
  } = props;
  const router = useRouter();
  const { logout } = useLogin();

  const { pathname: path } = router;
  const userIsLoggedIn = !loading && user;
  let [menuOpen, setMenuOpenState] = useState(false);
  let [profileMenuOpen, setProfileMenuOpenState] = useState(false);
  const [fixedNav, setFixedNav] = useState(false);
  const [navLinksStatus, setNavLinkStatus] = useState({});
  const [dropDownLinksStatus, setDropDownLinksStatus] = useState({});

  const [
    mobileEduLoanDropdownVisible,
    setMobileEduLoanDropdownVisible,
  ] = useState(false);

  const [
    mobileBankAccDropdownVisible,
    setmobileBankAccDropdownVisible,
  ] = useState(false);

  useEffect(() => {
    setNavLinkStatus({
      homeActive: path == "/" ? true : false,
      aboutActive: path == "/about" ? true : false,
      eduLoanActive:
        path == `/loan-features` ||
        path == `/loan-features/[country]` ||
        path == `/how-it-works/[country]` ||
        path == "/how-it-works" ||
        path == "/faq" ||
        path === "/[...article]"
          ? true
          : false,
      bankAccountActive:
        path == "/abroad/student-bank-account-for-usa" ||
        path == "/abroad/usa-bank-account/faqs" ||
        path == "/abroad/usa-bank-account/how-it-works"
          ? true
          : false,
    });

    setDropDownLinksStatus({
      loanFeatureActive: path == `/loan-features/[country]` ? true : false,
      howWorksActive: path == `/how-it-works/[country]` ? true : false,
      eduLoanFaqActive: path == "/faq" ? true : false,
      eduLoanMSActive: path == "/[...article]" ? true : false,
      bankAccFaqActive: path == "/abroad/usa-bank-account/faqs" ? true : false,
      accountFetauresActive:
        path == "/abroad/student-bank-account-for-usa" ? true : false,
      bankWorksActive:
        path == "/abroad/usa-bank-account/how-it-works" ? true : false,
    });
  }, []);
  useEffect(() => {
    if (eduLoanActive) {
      setMobileEduLoanDropdownVisible(true);
    } else if (bankAccountActive) {
      setmobileBankAccDropdownVisible(true);
    }
  }, [dropDownLinksStatus, navLinksStatus]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavSignIn = (flowType) => {
    initLogin(true, "", flowType);
  };

  const handleScroll = () => {
    if (window.pageYOffset >= 51) {
      setFixedNav(true);
    } else {
      setFixedNav(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpenState(!menuOpen);
  };

  const toggleProfileMenu = () => {
    setProfileMenuOpenState(!profileMenuOpen);
  };

  const useStyles = makeStyles(() =>
    createStyles({
      small: {
        width: 26,
        height: 26,
      },
    })
  );
  const classes = useStyles();

  const {
    homeActive,
    aboutActive,
    eduLoanActive,
    bankAccountActive,
  } = navLinksStatus;

  const {
    loanFeatureActive,
    howWorksActive,
    eduLoanFaqActive,
    eduLoanMSActive,
  } = dropDownLinksStatus;

  const activeEduLoanDisableOther = () => {
    setMobileEduLoanDropdownVisible(!mobileEduLoanDropdownVisible);
    if (mobileBankAccDropdownVisible) {
      setmobileBankAccDropdownVisible(false);
    }
  };

  const loginButton = (className) => {
    if (userIsLoggedIn && !showNavLinks) {
      return (
        <a
          onClick={() => logout()}
          className={`py-2 px-4 rounded w-full md:w-auto text-center text-sm bg-gray-100 text-blue-700 `}
        >
          Logout
        </a>
      );
    } else if (userIsLoggedIn && showNavLinks) {
      return (
        <a
          onClick={() => {
            logEvent("LF-Home", "Resume Application", router.asPath);
            router.push("/applications?clickId=34");
          }}
          className={`font-bold py-3 px-6 bg-primary text-white rounded`}
        >
          Resume Application
        </a>
      );
    } else {
      if (eduLoanActive) {
        return (
          <a
            onClick={() => {
              logEvent("LF-Home", "Get Loan Offer", router.asPath);
              fireEvent(EVENTS_CONSTANTS.INSCHOOL_GET_LOAN_OFFER);
              handleNavSignIn(flowTypes.INSCHOOL);
            }}
            className={`button ${className}`}
          >
            Get Loan Offer
          </a>
        );
      }
      if (bankAccountActive) {
        return (
          <a
            onClick={() => {
              logEvent("LF-Home", "Get US Bank Account", router.asPath);
              handleNavSignIn(flowTypes.BANK);
            }}
            className={`button ${className}`} // to remove hidden class after bank account flow integartion
          >
            Get US Bank Account
          </a>
        );
      }
    }
  };

  const getCtaButton = () => {
    if (eduLoanActive) {
      return (
        <a
          onClick={(e) => {
            e.preventDefault();
            logEvent(
              "LF-Home",
              `${user ? "Resume" : "Get Loan Offer"}`,
              router.asPath
            );
            `${
              user ? null : fireEvent(EVENTS_CONSTANTS.INSCHOOL_GET_LOAN_OFFER)
            }`;
            handleNavSignIn(flowTypes.INSCHOOL);
          }}
          className={`mr-2 bg-primary text-xs py-2 px-4 text-center font-bold text-white top-cta ${style.desktopHide}`}
          href="/applications?user_context=education"
        >
          {user ? "Resume" : "Get Loan Offer"}
        </a>
      );
    } else if (bankAccountActive) {
      return (
        <a
          onClick={() =>
            logEvent(
              "LF-Home",
              `${user ? "Resume" : "Get Bank Account"}`,
              router.asPath
            )
          }
          className={`mr-4 bg-primary text-xs py-2 px-4 text-center font-bold text-white top-cta ${style.desktopHide}`}
          href="/applications?user_context=bank"
        >
          {user ? "Resume" : "Get Bank Account"}
        </a>
      );
    } else {
      return (
        <button
          type="button"
          onClick={() => {
            if (user) {
              logEvent(
                "LF-Home",
                `${user ? "Resume" : ctaText.GET_LOAN_OFFER}`,
                router.asPath
              );
              router.push("/applications?clickId=35");
            } else {
              trackClick(pageName.HOME_PAGE, {
                widgetName: "Header",
                widgetFormat: "Banner",
                contentName: ctaText.GET_LOAN_OFFER,
                contentFormat: "Button",
              });
              initLogin();
            }
          }}
          className={`mr-4 bg-primary text-xs py-2 px-4 text-center font-bold text-white ${style.topCta} ${style.desktopHide}`}
        >
          {user ? "Resume" : ctaText.GET_LOAN_OFFER}
        </button>
      );
    }
  };
  return (
    <div>
      {router.pathname === "/" && !menuOpen && (
        <button
          type="button"
          className="bg-primary block w-full text-center text-white px-4 md:px-0 py-3 font-bold text-xl md:text-2lg cursor-pointer"
          onClick={() => {
            trackClick(pageName.HOME_PAGE, {
              widgetName: "Header",
              widgetFormat: "Banner",
              contentFormat: "Banner",
              contentName: "Get Your Offer Now",
            });
            if (user) {
              router.push("/applications");
            } else {
              initLogin();
            }
          }}
        >
          {underlineLastNWords(mainBannerText, 2) || "Get your Loan Offer NOW!"}
        </button>
      )}
      <div
        className={
          "navbar-bg-overlay" +
          (!(menuOpen || profileMenuOpen) ? " hidden" : "")
        }
        onClick={() => {
          toggleMenu();
          toggleProfileMenu();
        }}
      />
      <nav
        className={`border border-gray-300 md:border-0 ${
          style.mainNavbar
        } ${showBackground && "md:bg-white"} ${forceMobileBackground &&
          "bg-transparent border-0"} ${
          fixedNav || menuOpen || router.pathname !== "/"
            ? "fixed top-0"
            : "absolute"
        } left-0 right-0 ${
          invertLogo ? `bg-primary ${style.invertLogo}` : "bg-white"
        } `}
      >
        <div className="max-w-page-width flex justify-between h-full items-center mx-auto flex-wrap flex-grow-1">
          <div className={`${style.mainLogo} ml-6 xl:ml-0`}>
            <a
              href={
                router?.pathname?.includes("disbursements")
                  ? "/applications"
                  : "/"
              }
            >
              <Image
                src={`${imageBasePath}/assets/images/company-logo/logo${
                  invertLogo ? "-white" : ""
                }.svg`}
                className="header-logo"
                width={155}
                height={29.9}
                alt="Leap Finance Logo"
              />
            </a>
          </div>
          <div className="flex-grow hidden sm:block">{middleElement}</div>
          {showAvatar && userIsLoggedIn && (
            <Fragment>
              <div className="inline-block mr-2 md:mr-4">
                <button
                  onClick={() => {
                    toggleMenu();
                    toggleProfileMenu();
                  }}
                >
                  <div className="inline-block avatar-holder rounded-full border-2 align-middle mr-2 -mt-1">
                    <Avatar
                      className={classes.small}
                      alt={user?.mobile}
                      src={`${imageBasePath}/assets/icons/icon-user.svg`}
                    >
                      {user?.mobile}
                    </Avatar>
                  </div>
                  <span
                    className={`text-sm font-semibold ${
                      !invertLogo ? "text-gray-700" : "text-white"
                    }`}
                  >
                    <span className="md:inline-block hidden">
                      {user?.mobile}
                    </span>
                  </span>
                  <span className="ml-2">
                    <Image
                      className="inline-block"
                      src={`${imageBasePath}/assets/icons/bottom-carat.svg`}
                      width={10}
                      height={6}
                      alt="Menu open icon"
                    />
                  </span>
                </button>
                {profileMenuOpen && (
                  <div
                    className="w-full block bg-white px-2 py-2 fixed md:w-auto rounded-sm mt-2 -ml-16 md:ml-0"
                    style={{
                      boxShadow:
                        "0px 8px 16px rgba(130, 136, 148, 0.16), 0px 0px 2px rgba(130, 136, 148, 0.18)",
                    }}
                  >
                    <div className="text-base text-gray-700 leading-loose">
                      <a
                        href="/applications"
                        className="block pl-2 pr-16 hover:bg-gray-250 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          const slug = localStorageUtils?.getItem(
                            "applicationSlug"
                          );
                          slug
                            ? router.push(`/disbursements/${slug}`)
                            : router.push("/applications");
                        }}
                      >
                        {router?.pathname?.includes("disbursements")
                          ? "Dashboard"
                          : "Applications"}
                      </a>
                      <a
                        onClick={() => logout()}
                        className="block pl-2 pr-16 hover:bg-gray-250 cursor-pointer"
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          )}
          {showNavLinks && (
            <Fragment>
              <div
                className={`${style.desktopHide} align-middle -mr-1 pr-2 flex justify-end ${style.navHamburgerIcon}`}
              >
                {bankAccountActive && !menuOpen && (
                  <a
                    onClick={() =>
                      logEvent(
                        "US Bank Account",
                        "Clicked on US Bank Account in the Mobile NavBar",
                        router.asPath
                      )
                    }
                    className={`mr-2 bg-primary text-xs py-2 px-4 text-center font-bold text-white ${style.desktopHide} rounded`}
                    href={`${user ? "/applications" : "/api/login"}`}
                  >
                    {user ? "Resume" : "Get Bank Account"}
                  </a>
                )}
                {eduLoanActive && !menuOpen && (
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      logEvent(
                        "LF-Home",
                        `${user ? "Resume" : ctaText.GET_LOAN_OFFER}`,
                        router.asPath
                      );
                      `${
                        user
                          ? null
                          : fireEvent(EVENTS_CONSTANTS.INSCHOOL_GET_LOAN_OFFER)
                      }`;
                      handleNavSignIn(flowTypes.INSCHOOL);
                    }}
                    className={`mr-2 bg-primary text-xs py-2 px-4 text-center font-bold text-white ${style.desktopHide} rounded`}
                    href="/applications?user_context=education"
                  >
                    {user ? "Resume" : ctaText.GET_LOAN_OFFER}
                  </a>
                )}
                {(aboutActive || homeActive) && !menuOpen && (
                  <button
                    type="button"
                    onClick={() => {
                      logEvent(
                        "LF-Home",
                        `${user ? "Resume" : ctaText.GET_LOAN_OFFER}`,
                        router.asPath
                      );
                      if (user) {
                        router.push("/applications?clickId=35");
                      } else {
                        trackClick(pageName.HOME_PAGE, {
                          widgetName: "Header",
                          widgetFormat: "Banner",
                          contentName: ctaText.GET_LOAN_OFFER,
                          contentFormat: "Button",
                        });
                        initLogin();
                      }
                    }}
                    className={`mr-2 bg-primary text-xs py-2 px-4 text-center font-bold text-white ${style.desktopHide} rounded`}
                  >
                    {user ? "Resume" : ctaText.GET_LOAN_OFFER}
                  </button>
                )}
                <button
                  className="flex items-center text-primary"
                  onClick={() => {
                    toggleMenu();
                    toggleProfileMenu();
                  }}
                >
                  {menuOpen ? (
                    <button type="button" className="flex items-center">
                      {getCtaButton()}
                      <Image
                        src={`${imageBasePath}/assets/icons/exit.svg`}
                        width={20.38}
                        height={20.38}
                        alt="Menu exit icon"
                        className="max-w-full h-auto"
                      />
                    </button>
                  ) : (
                    <svg
                      className={`fill-current h-6 w-6 ${style.desktopHide}`}
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>{"Menu"}</title>
                      <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                    </svg>
                  )}
                </button>
              </div>
              <div className={`${style.desktopHide}`}>
                <div
                  className={`${style.primaryMenu} ${
                    menuOpen ? `${style.menuOpen}` : ""
                  }`}
                >
                  <div className={`${style.menuLeft}`}>
                    <div className={`${style.menuContainer}`}>
                      <ul className={`${style.menuList}`}>
                        <li>
                          <Link
                            href={{
                              pathname: "/about",
                              query: { clickId: "1" },
                            }}
                            className={`${aboutActive &&
                              "text-primary font-bold"}`}
                          >
                            About Us
                          </Link>
                        </li>
                        <li>
                          <div
                            className={`${style.navEduloanMbile}`}
                            onClick={activeEduLoanDisableOther}
                          >
                            <p>Education Loan</p>
                            <div
                              className={`${style.navArrowMobile} ${
                                !mobileEduLoanDropdownVisible
                                  ? `${style.arrowDown}`
                                  : `${style.arrowUp}`
                              }`}
                            ></div>
                          </div>

                          <ul
                            className={`${
                              style.mobileDropdown
                            } ${!mobileEduLoanDropdownVisible && "hidden"}`}
                          >
                            <li>
                              <Link
                                href={{
                                  pathname: "/loan-features/[country]",
                                  query: {
                                    clickId: "2",
                                  },
                                }}
                                as={`/loan-features/${COUNTRY_LIST.USA}`}
                                onClick={() => {
                                  logEvent(
                                    "LF-Home",
                                    "In School Loan Features",
                                    "USA"
                                  );
                                }}
                                className={`${loanFeatureActive &&
                                  "text-primary font-bold"}`}
                              >
                                Loan Features
                              </Link>
                            </li>
                            <li>
                              <Link
                                href={{
                                  pathname: "/how-it-works/[country]",
                                  query: { clickId: "3" },
                                }}
                                as={`/how-it-works/${COUNTRY_LIST.USA}`}
                                onClick={() => {
                                  logEvent(
                                    "LF-Home",
                                    "In School How it Works",
                                    "USA"
                                  );
                                }}
                                className={`${howWorksActive &&
                                  "text-primary font-bold"}`}
                              >
                                How it works
                              </Link>
                            </li>
                            <li>
                              <Link
                                href={{
                                  pathname: "/faq",
                                  query: { clickId: "4" },
                                }}
                                onClick={() => {
                                  logEvent("LF-Home", "In School FAQs", "");
                                }}
                                className={`${eduLoanFaqActive &&
                                  "text-primary font-bold"}`}
                              >
                                FAQ's
                              </Link>
                            </li>
                            <li>
                              <Link
                                href={{
                                  pathname: "/[...article]",
                                  query: { clickId: "5" },
                                }}
                                as="/education-loan-for-ms-in-usa"
                                onClick={() => {
                                  logEvent(
                                    "LF-Home",
                                    "In School Education Loan MS",
                                    ""
                                  );
                                }}
                                className={`${eduLoanMSActive &&
                                  "text-primary font-bold"}`}
                              >
                                Education loan for MS
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link
                            href="https://reviews.leapfinance.com/"
                            onClick={() => {
                              trackClick(pageName.HOME_PAGE, {
                                widgetName: "Header",
                                widgetFormat: "Banner",
                                contentName: "Testimonials",
                                contentFormat: "Link",
                              });
                              logEvent("LF-Home", "Testimonials", "Header");
                            }}
                          >
                            Testimonials
                          </Link>
                        </li>
                      </ul>
                      <div className={`${style.navContact}`}>
                        <h2>Contact Us</h2>
                        <a
                          onClick={() =>
                            logEvent(
                              "Contact",
                              "Clicked on Talk to us in Mobile NavBar",
                              `${router.asPath}`
                            )
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          href={companyContacts.link.phone}
                        >
                          <Image
                            src={`${imageBasePath}/assets/images/index/icon-phone.svg`}
                            alt="Phone"
                            width={20}
                            height={20}
                            className="max-w-full h-auto"
                          />
                          <p className="ml-2">
                            {companyContacts.display.phone}
                          </p>
                        </a>
                        <a
                          onClick={() =>
                            logEvent(
                              "Contact",
                              "Clicked on WhatsApp us in Mobile NavBar",
                              `${router.asPath}`
                            )
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://api.whatsapp.com/send?phone=${companyContacts.link.whatsApp}`}
                        >
                          <Image
                            src={`${imageBasePath}/assets/images/index/icon-whatsapp.svg`}
                            alt="WhatsApp"
                            width={20}
                            height={20}
                            className="max-w-full h-auto"
                          />
                          <p className="ml-2">
                            {companyContacts.display.whatsApp}
                          </p>
                        </a>
                        <a
                          onClick={() =>
                            logEvent(
                              "Contact",
                              "Clicked on Email us in Mobile NavBar",
                              `${router.asPath}`
                            )
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          href={companyContacts.link.email}
                        >
                          <Image
                            src={`${imageBasePath}/assets/images/index/icon-mail.svg`}
                            alt="Email"
                            width={20}
                            height={20}
                            className="max-w-full h-auto"
                          />
                          <p className="ml-2">
                            {companyContacts.display.email}
                          </p>
                        </a>

                        {!userIsLoggedIn && (
                          <button
                            type="button"
                            className="bg-white text-primary py-4 px-6 mt-4 font-bold w-full block border border-primary text-center rounded"
                            onClick={() => {
                              trackClick(generatePageName(), {
                                widgetName: "Header",
                                widgetFormat: "Banner",
                                contentName: "Sign In",
                                contentFormat: "Button",
                              });
                              logEvent("LF-Home", "Sign In", router.asPath);
                              handleNavSignIn(flowTypes.INSCHOOL);
                            }}
                          >
                            Sign in
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* desktop nav */}
              <div className={`${style.desktopNav}`}>
                <div
                  className={
                    `w-full block lg:flex lg:items-center bg-white lg:bg-transparent ${style.primaryMenu}` +
                    (!menuOpen ? " hidden" : `${style.menuOpen}`)
                  }
                >
                  <nav className={`text-sm lg:flex-grow ${style.newNav}`}>
                    <ul>
                      <li>
                        <Link
                          href={{ pathname: "/about", query: { clickId: "1" } }}
                          className={`${style.headerMenuItem} ${aboutActive &&
                            `text-primary font-bold ${style.headerMenuItemActive}`}`}
                        >
                          About Us
                        </Link>
                      </li>
                      <li className={`${style.hoverableNav}`}>
                        <div
                          className={`${style.headerMenuItem} ${eduLoanActive &&
                            `${style.headerMenuItemActive}`}`}
                        >
                          Education Loan
                        </div>
                        <div className={`${style.hoverableNavArrow}`}></div>
                        <div className={`${style.hoverableNavDropdown}`}>
                          <ul>
                            <li
                              className={`style.showExtraFeature ${loanFeatureActive &&
                                "text-primary font-bold"}`}
                            >
                              <Link
                                href={{
                                  pathname: "/loan-features/[country]",
                                  query: { clickId: "2" },
                                }}
                                as={`/loan-features/${COUNTRY_LIST.USA}`}
                                onClick={() => {
                                  logEvent(
                                    "LF-Home",
                                    "In School Loan Features",
                                    "USA"
                                  );
                                }}
                              >
                                Loan Features
                              </Link>
                            </li>
                            <li
                              className={`${
                                style.showExtraFeature
                              } ${howWorksActive && "text-primary font-bold"}`}
                            >
                              <Link
                                href={{
                                  pathname: "/how-it-works/[country]",
                                  query: { clickId: "3" },
                                }}
                                as={`/how-it-works/${COUNTRY_LIST.USA}?clickId=3`}
                                onClick={() => {
                                  logEvent(
                                    "LF-Home",
                                    "In School How it Works",
                                    "USA"
                                  );
                                }}
                              >
                                How it works
                              </Link>
                            </li>
                            <li
                              className={`${eduLoanFaqActive &&
                                "text-primary font-bold"}`}
                            >
                              <Link
                                href={{
                                  pathname: "/faq",
                                  query: { clickId: "4" },
                                }}
                                onClick={() => {
                                  logEvent("LF-Home", "In School FAQs", "");
                                }}
                              >
                                FAQ` s
                              </Link>
                            </li>
                            <li
                              className={`${eduLoanMSActive &&
                                "text-primary font-bold"}`}
                            >
                              <Link
                                href={{
                                  pathname: "/[...article]",
                                  query: { clickId: "5" },
                                }}
                                as="/education-loan-for-ms-in-usa"
                                onClick={() => {
                                  logEvent(
                                    "LF-Home",
                                    "In School Education Loan MS",
                                    ""
                                  );
                                }}
                              >
                                Education loan for MS
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <Link
                          href="https://reviews.leapfinance.com/"
                          className={`${style.headerMenuItem} `}
                          onClick={() => {
                            trackClick(pageName.HOME_PAGE, {
                              widgetName: "Header",
                              widgetFormat: "Banner",
                              contentName: "Testimonials",
                              contentFormat: "Link",
                            });
                            logEvent("LF-Home", "Testimonials", "Header");
                          }}
                        >
                          Testimonials
                        </Link>
                      </li>
                      {!userIsLoggedIn && (
                        <li>
                          <button
                            type="button"
                            className={`${style.headerMenuItem} ${style.headerMenuItemSignIn} py-2 px-6 bg-white text-primary rounded font-bold ml-1`}
                            style={{
                              border: "1px solid blue",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              trackClick(generatePageName(), {
                                widgetName: "Header",
                                widgetFormat: "Banner",
                                contentName: "Sign In",
                                contentFormat: "Button",
                              });
                              logEvent("LF-Home", "Sign In", router.asPath);
                              handleNavSignIn(flowTypes.INSCHOOL);
                            }}
                          >
                            Sign in
                          </button>
                        </li>
                      )}
                      {!userIsLoggedIn && !eduLoanActive && !bankAccountActive && (
                        <li>
                          <button
                            type="button"
                            onClick={() => {
                              trackClick(pageName.HOME_PAGE, {
                                widgetName: "Header",
                                widgetFormat: "Banner",
                                contentName: ctaText.GET_LOAN_OFFER,
                                contentFormat: "Button",
                              });
                              logEvent("LF-Home", ctaText.GET_LOAN_OFFER, router.asPath);
                              handleNavSignIn(flowTypes.INSCHOOL);
                            }}
                            className="py-2 px-6 bg-primary text-white rounded font-bold ml-1"
                            style={{
                              border: "1px solid blue",
                              fontSize: "14px",
                            }}
                          >
                            {ctaText.GET_LOAN_OFFER}
                          </button>
                        </li>
                      )}
                      <li>
                        <div className="hidden md:inline-block">
                          {loginButton("button-sm")}
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </Fragment>
          )}
        </div>
        {showEllisBanner && <EllisBanner />}
      </nav>
    </div>
  );
};

export default withLogin(Header);

export const initYM = () => {
  window.ymConfig = { bot: process.env.YMBOTID };

  (function() {
    var w = window;
    var ic = w.YellowMessenger;

    if (typeof ic === "function") {
      console.log("updater call");
      ic("reattach_activator");
      ic("update", ymConfig);
    } else {
      var d = document;
      var i = function() {
        i.c(arguments);
      };

      function l() {
        console.log("script creation");
        var e = d.createElement("script");
        e.type = "text/javascript";
        e.async = true;
        e.src = "https://app.yellowmessenger.com/widget/main.js";
        var t = d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(e, t);
      }

      i.q = [];

      i.c = function(e) {
        i.q.push(e);
      };

      w.YellowMessenger = i;
      l();

      // Consider using event listeners instead of attachEvent (not shown in the code)
    }
  })();
};
